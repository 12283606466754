import { useState } from "react"
import UsersList from "./UsersList"
import UserslistStudyHours from "./UsersListStudyHours"

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
const EventMembers = (props) => {
  const [tabSelected, setTabSelected] = useState("Attendance");
  const [navigation, setNavigation] = useState([
    { name: 'Attendance', href: '#', current: true },
    { name: 'Exempt', href: '#', current: false },
    { name: 'Absent', href: '#', current: false },
    { name: 'Semester Exempt', href: '#', current: false },
  ]);

  const tabSelect = (name) => {
    const updatedNavigation = navigation.map((item) => ({
          ...item,
          current: item.name === name,
        }));
    setNavigation(updatedNavigation);
    setTabSelected(name);
  }

  if (props.Event.EventType === 'StudyHours') {
    return (
      <div className="border-b border-gray-200 pb-0 sm:pb-0">
        <div className="mt-3 sm:mt-4">
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              <a
                key="Attendance"
                href="#"
                className={classNames(
                  'border-blue-500 text-blue-600',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                aria-current="page"
              >
                Attendance
              </a>
            </nav>
          </div>
          <UserslistStudyHours Members={props.Present}/>
        </div>
      </div>
    );
  }

  return (
    <div className="border-b border-gray-200 pb-0 sm:pb-0">
      <div className="mt-3 sm:mt-4 ">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            defaultValue={navigation.find((tab) => tab.current).name}
          >
            {navigation.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block ">
          <nav className="-mb-px flex space-x-8">
            {navigation.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                )}
                onClick={() => tabSelect(tab.name)}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
        { tabSelected === 'Attendance' && <UsersList Members={props.Present} type={'Attendance'}></UsersList>}
        { tabSelected === 'Exempt' && <UsersList Members={props.Exempt} type={'Exempt'}></UsersList>}
        { tabSelected === 'Absent' && <UsersList Members={props.Missing} type={'Absent'}></UsersList>}
        { tabSelected === 'Semester Exempt' && <UsersList Members={props.SemesterExempt} type={'Exempt'}></UsersList>}
      </div>
    </div>
  )
}
export default EventMembers;