import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import EventMembers from './EventMembers.js'
import '../../../styles/Modals.css';
import apiFetch from '../../../scripts/api.js';

const  EventModal = (props) => {
  const cancelButtonRef = useRef(null)
  const [isMounted, setIsMounted] = useState(true); 
  const [event, setEvent] = useState(null);
  const [stats, setStats] = useState(null);
  const [presentMembers, setPresentMembers] = useState(null); 
  const [exemptMembers, setExemptMembers] = useState({});
  const [missingMembers, setMissingMembers] = useState({});
  const [semesterExemptMembers, setSemesterExemptMembers] = useState({});

  useEffect( () => {
    setIsMounted(true);
    const fetch = async () => {
      if (isMounted) {
        try {
          const updatedEvent = await apiFetch(`/api/auth/organizations/events/${props.event.id}`);
          if (updatedEvent.success) {
            setEvent(updatedEvent.data);
            const stats = [
              {Stat: 'Attendance Count', value: updatedEvent.data.AttendanceMembers},
              {Stat: 'Percent Attendance', value: updatedEvent.data.PercentAttendance},
              {Stat: 'Missing Members', value: updatedEvent.data.MissingMembers},
            ];
            setStats(stats);
          }else {
            throw new Error('Failed to fetch event');
        }
          const ResponseEventData = await apiFetch(`/api/auth/organizations/events/${props.event.id}/details`);
          if (ResponseEventData.success) {
              const data = await ResponseEventData.data;
              setPresentMembers(data.presentMembers);
              setExemptMembers(data.exemptions);
              setMissingMembers(data.notPresentMembers);
          } else {
              throw new Error('Failed to fetch event details');
          }
          const semester = await apiFetch(`/api/auth/organizations/exemption/semester`);
          setSemesterExemptMembers(semester.data);
         } catch (error) {
          console.error('Error fetching organization:', error);
        } finally {
          setIsMounted(false);
        }
      }
    }
    fetch();
  }, []); 

  if (isMounted) {
    return (<div></div>);
  }

  return (
    <div>
      <Transition.Root show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  max-width-large sm:p-6 md:w-1/2 lg:w-1/2">
                  <div className="scrollbar-hidden overflow-auto max-w-1/2 max-h-[70vh]">
                    <div className=" sm:items-start justify-context-center">
                      <div className="mt-3 text-center sm:ml-5 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Event Information Report
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                          </p>
                        </div>
                        <EventMembers Present={presentMembers} Missing={missingMembers} Exempt={exemptMembers} SemesterExempt={semesterExemptMembers} Event={props.event}/>
                        </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default EventModal;