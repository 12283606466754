import { Container } from './Container'
import { Logo } from './Logo'
import { Button } from './Button'

export function Header() {
  return (
    <header className="bg-white">
      <nav>
        <Container className="relative z-50 flex justify-between items-center py-4">
          <div className="flex items-center">
            <a href="/" aria-label="Home" className="flex items-center">
            < img src="./images/Logos/InfinityLogo.png" alt="Logo" className="h-16 w-auto" />
            </a>
          </div>
          <div className="flex items-center">
            <Button
              href="/signin"
              variant="outline"
              className="border border-gray-300 text-gray-700 hover:bg-gray-100 px-4 py-2 rounded-md"
            >
              Admin Dashboard
            </Button>
          </div>
        </Container>
      </nav>
    </header>
  )
}
