import ModeratorList from './ModeratorsList';
import AddModeratorModal from './AddModeratorModal';
import { useState } from 'react';
import GeocoderExample from '../Locations/Geocoder';
import MapComponent from '../Locations/MapComponent';
import AddLocationForm from '../Locations/AddLocationForm';
import OrganizationPlan from './OrganizationPlan';
import LoadingComponent from '../../Alerts/LoadingComponent';

const  Organization = (props) => {
  const [open, setOpen] = useState(false);

  if(!props.Moderators || !props.OrganizationContext) { return <LoadingComponent/>}

  return (
    <div>
       <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Organization Information</h3>
          </div>
          <div className="mt-6 border-t border-gray-100 mx-auto my-8 p-4 sm:p-6 md:p-8 bg-white rounded-lg shadow-md">
            <dl className="divide-y divide-gray-100">
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">Organization Name</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.OrganizationContext.Name}</dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">Organization Email</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.OrganizationContext.Email}</dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">Organization Address</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.OrganizationContext.Address}</dd>
              </div>
              <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900">Join Code</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{props.OrganizationContext.JoinCode}</dd>
              </div>
              <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                <dt className="text-sm font-medium leading-6 text-gray-900 sm:col-span-1">Chapter Attendance Policies</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2">Quorum: {props.OrganizationContext.Quorum}%</dd>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-start-2 sm:col-span-2">Unexcused Limit: {props.OrganizationContext.UnexcusedLimit}</dd>
              </div>
              <div className="flex flex-wrap -mx-4">
                <div className="bg-white px-4 py-6 w-full sm:w-1/2 sm:px-3">
                  <dd className="mt-1 text-sm leading-6 text-gray-700">
                    <ModeratorList Moderators={props.Moderators} Organization={props.OrganizationContext.Name} SetAddOpen={setOpen}></ModeratorList>
                  </dd>
                </div>
                <div className="bg-white px-4 py-6 w-full sm:w-1/2 sm:px-3">
                  <OrganizationPlan/>
                </div>
              </div>
            </dl>
          </div>
        </div>      
        <AddModeratorModal Members={props.Members} Open={open} setOpen={setOpen} Organization={props.OrganizationContext.Name}/>
    </div>
  )
}

export default Organization;