import { Fragment, useState, useEffect } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, ClockIcon, EllipsisHorizontalIcon,} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import EventFormModal from './EventFormModal'
import apiFetch from '../../../scripts/api'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const Calendar = (props) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [update, setUpdate] = useState(false); 

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [events, setEvents] = useState([]);
    const [days, setDays] = useState([]);
    const [currentDay, setCurrentDay] = useState();
    const [selectedEvent, setSelectedEvent] = useState(null);

    const selectedDay = days.find((day) => day.date === currentDay);

    const Refresh = () => {
      setUpdate(!update);
    }  

    const getMonthName = (monthIndex) => {
      return monthNames[monthIndex]; 
    };

    const incrementMonth = () => {
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
    };
  
    const decrementMonth = () => {
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
    };

    const goToToday = () => {
      const today = new Date();
      setCurrentMonth(today.getMonth());
      setCurrentYear(today.getFullYear());
    };

    const getTodayString = () => {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return today.toISOString().split('T')[0];
    };
  
    const processEvents = (events) => {
      const eventMap = {};
    
      events.forEach(event => {
        let startDate = null;
        if (typeof event.StartDate === 'object' && event.StartDate.seconds) {
          startDate = new Date(event.StartDate.seconds * 1000);
          startDate.setHours(startDate.getHours() - 5);
        } else if (typeof event.StartDate === 'string') {
            startDate =  new Date(event.StartDate);
        } else {
            throw new Error('Invalid date format');
        }
        const dateString = startDate.toISOString().split('T')[0];
        const timeString = startDate.toISOString().split('T')[1].substring(0, 5);
        if (!eventMap[dateString]) {
          eventMap[dateString] = [];
        }
        eventMap[dateString].push({
          id: event.id,
          name: event.EventName,
          time: timeString, // Time in 'HH:mm' format
          datetime: `${dateString}T${timeString}`, // Combined date and time in 'YYYY-MM-DDTHH:mm' format
          href: '#' 
        });
      });
    
      const days = Object.keys(eventMap).map(date => ({
        date,
        events: eventMap[date],
        isCurrentMonth: new Date(date).getMonth() === new Date().getMonth() 
      }));
    
      return days;
    };
    
    const generateCalendarDays = (currentMonth, currentYear) => {
      const daysInMonth = getDaysInMonth(currentMonth, currentYear);
      const firstDayOfWeek = new Date(currentYear, currentMonth, 1).getDay();
      let emptySlots = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; 

      const emptyDays = Array.from({ length: emptySlots }, (_, index) => ({
        date: `empty-${getDaysInMonth(currentMonth - 1, currentYear) + index - emptySlots + 1}`, 
        events: [],
        isCurrentMonth: false,
      }));
    
      const monthDays = Array.from({ length: daysInMonth }, (_, i) => {
        const day = i + 1; // Day of the month
        const date = new Date(currentYear, currentMonth, day).toISOString().split('T')[0];
        return { date, events: [], isCurrentMonth: true };
      });
    
      return [...emptyDays, ...monthDays];
    };
    
    const getDaysInMonth = (month, year) => {
      return new Date(year, month + 1, 0).getDate();
    };

    useEffect(() => {
      const fetchEvents = async () => {
        try {
          const allEvents = await apiFetch('/api/auth/organizations/all-events');
          setEvents(allEvents.data);
          const newDays = processEvents(allEvents.data);
          setDays(newDays);
          setCurrentDay(getTodayString());
        } catch (error) {
          console.error('Error fetching events:', error);
        }
      };
  
      fetchEvents();
    }, [update]);

    useEffect(() => {
      const newDays = processEvents(events);
      const allDays = generateCalendarDays(currentMonth, currentYear);
      const mergedDays = allDays.map(day => {
        const foundDay = newDays.find(event => event.date === day.date);
        return foundDay || day;
      });
      setDays(mergedDays);
    }, [events, currentMonth, currentYear]);

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <EventFormModal open={openAdd} setOpen={setOpenAdd} CRUD="CREATE" Organization={props.user.Organization} Refresh={Refresh}></EventFormModal>
      <EventFormModal open={openEdit} setOpen={setOpenEdit}  CRUD="UPDATE" event={selectedEvent} Organization={props.user.Organization} Refresh={Refresh}></EventFormModal>
      <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
        <h1 className="text-base font-semibold leading-6 text-gray-900">
          <time dateTime={`${currentYear}-${currentMonth + 1 < 10 ? `0${currentMonth + 1}` : currentMonth + 1}-01`}>
            {getMonthName(currentMonth)} {currentYear}
          </time>
        </h1>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
              onClick={decrementMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
              onClick={goToToday}
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
              onClick={incrementMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              type="button"
              className="ml-6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => setOpenAdd(true)} 
            >
              Add event
            </button>
          </div>
          <Menu as="div" className="relative ml-6 md:hidden">
            <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => setOpenAdd(true)} 
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Create event
                      </a>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={goToToday}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Go to today
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </header>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
        </div>
        <div className="flex bg-gray-100 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
            {days.map((day) => (
              <div
                key={day.date}
                className={classNames(
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                  'relative px-3 py-2'
                )}
                style={{ minHeight: '7rem' }} 
              >
                <time
                  dateTime={day.date}
                  className={
                    day.isToday
                      ? 'flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 font-semibold text-white'
                      : undefined
                  }
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
                {day.events.length > 0 && (
                  <ol className="mt-2">
                    {day.events.slice(0, 2).map((event) => (
                      <li key={event.id}>
                        <a 
                        href={event.href} 
                        className="group flex"
                        onClick={() => {
                          setSelectedEvent(event.id);
                          setOpenEdit(true);
                        }}>
                          <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-blue-600">
                            {event.name}
                          </p>
                          <time
                            dateTime={event.datetime}
                            className="ml-3 hidden flex-none text-gray-500 group-hover:text-blue-600 xl:block"
                          >
                            {event.time}
                          </time>
                        </a>
                      </li>
                    ))}
                    {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                  </ol>
                )}
              </div>
            ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                key={day.date}
                type="button"
                onClick={() => setCurrentDay(day.date)}
                className={classNames(
                  day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isToday && 'text-blue-600',
                  !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                  !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                  'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10'
                )}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                    day.isSelected && day.isToday && 'bg-blue-600',
                    day.isSelected && !day.isToday && 'bg-gray-900',
                    'ml-auto'
                  )}
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
                <span className="sr-only">{day.events.length} events</span>
                {day.events.length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {day.events.map((event) => (
                      <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {selectedDay?.events.length > 0 && (
        <div className="px-4 py-10 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {selectedDay.events.map((event) => (
              <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">{event.name}</p>
                  <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                    <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {event.time}
                  </time>
                </div>
                <a
                  onClick={() => {
                    setSelectedEvent(event.id);
                    setOpenEdit(true);
                  }}
                  className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                >
                  Edit<span className="sr-only">, {event.name}</span>
                </a>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  )
}
export default Calendar;
