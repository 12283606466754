import React from 'react';

// Utility function to get the semester code for a given year and month
const getSemesterCode = (year, month) => {
  return `${year}${month <= 6 ? 1 : 2}`;
};

// Utility function to convert semester code to readable format
const formatSemesterCode = (semesterCode) => {
  const year = semesterCode.slice(0, 4);
  const semester = semesterCode.slice(4) === '1' ? 'Spring' : 'Fall';
  return `${semester} ${year}`;
};

const generateSemesters = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; 
  const currentSemester = getSemesterCode(currentYear, currentMonth);

  const semesters = [{ code: currentSemester, name: formatSemesterCode(currentSemester) }];

  for (let i = 0; semesters.length < 4; i++) {
    const previousDate = new Date(currentDate);
    previousDate.setMonth(currentDate.getMonth() - (i * 6));

    const previousYear = previousDate.getFullYear();
    const previousMonth = previousDate.getMonth() + 1; 
    const previousSemester = getSemesterCode(previousYear, previousMonth);

    if (!semesters.some(sem => sem.code === previousSemester)) {
      semesters.push({ code: previousSemester, name: formatSemesterCode(previousSemester) });
    }
  }

  return semesters.slice(0, 4); 
};


const SemesterSelector = (props) => {
  const semesters = generateSemesters();
  return (
    <select
      onChange={(e) => props.Change(e.target.value)}
      required
      defaultValue=""
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
    >
      <option value="" disabled>Select Semester</option>
      {semesters.map((semester) => (
        <option key={semester.code} value={semester.code}>{semester.name}</option>
      ))}
    </select>
  );
};

export default SemesterSelector;
