import { useState, useCallback } from "react";
import {signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider, getIdToken, getAuth} from "firebase/auth";
import { auth} from "../firebase-config";
import "../styles/SignInScreen.css";
import AppUser from "../classes/AppUser";
import ErrorMessageAlert from "../components/Alerts/ErrorMessageAlert";
import { useNavigate } from 'react-router-dom';
import SuccessMessageAlert from "../components/Alerts/SuccessMessageAlert";
import apiFetch from "../scripts/api";

const SignInScreen = (props) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [ErrorMessage, setErrorMessage] = useState('');
  const [SuccessMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      ).catch((error) => {
        if (error.code === 'auth/invalid-login-credentials') {
          setErrorMessage("Invalid login credentials");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        } else {
            alert(error.code);
        }
      });
      const userInfo = user.user;
      const userProfileResponse = await apiFetch(`/api/public/userprofile/${userInfo.uid}`);
      const userProfile = userProfileResponse.data;
      const DisplayName = userProfile ? (userProfile["DisplayName"] || "No Display Name") : "No User Profile";
      const role = userProfile ? userProfile["Role"] : "No Role";
      const Organization = userProfile ? userProfile["Organization"] : null;
      const newUser = new AppUser(DisplayName, userInfo.email, userInfo.uid, role, Organization);

      await props.setUser(newUser);
      navigate('/dashboard');
    } catch (error) {
      console.log(error.message);
    }
  };

  const navigateTo = useCallback((route) => {
    navigate(`/${route}`);
  }, []);

  const googleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  } catch (error) {
      console.error('Google sign-in error:', error.message);
  }  }

  const handlePasswordReset = async () => {

    if(loginEmail != ''){
      await sendPasswordResetEmail(auth, loginEmail)
      .then(() => {
        setSuccessMessage("If your email is associated with an account, a reset email has been sent. Please wait a few minuets.");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
     })
      .catch(error => {
        setErrorMessage(error.message);
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      });
    }else{
      setErrorMessage("Enter your email before resetting.");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
    }
    
  };

  const backButton = async () => {
    props.setAction("");
  }

  return (
    <div className="sign-in-screen-in">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-32 w-auto"
            src="./images/Logos/InfinityLogo.png"
            alt="Chapter Check"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Admin Dashboard Login
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  onChange={(event) => {
                    setLoginEmail(event.target.value);
                  }}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <button onClick={() => handlePasswordReset()} className="font-semibold text-blue-600 hover:text-blue-500">
                    Forgot password?
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  onChange={(event) => {
                    setLoginPassword(event.target.value);
                  }}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                onClick={login}
              >
                Sign in
              </button>
            </div>
          </div>

          {/* <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <button onClick={() => navigateTo("signup")} className="font-semibold leading-6 text-blue-600 hover:text-blue-500">
              Sign Up
            </button>
          </p> */}
          <p className="mt-1 pt-10 text-center text-sm text-gray-500">
            <button onClick={() => navigateTo("")} className="font-semibold leading-6 text-grey-600 hover:text-blue-100">
              Return Home
            </button>
          </p>
        </div>
        <div>
        {ErrorMessage &&
          <ErrorMessageAlert ErrorMessage={ErrorMessage}></ErrorMessageAlert>
        }
        {SuccessMessage && 
          <SuccessMessageAlert SuccessMessage={SuccessMessage}/>
        }        
        </div>
      </div>
    </div>
  );
};

export default SignInScreen;
