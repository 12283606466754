import MapComponent from './MapComponent';
const Geocoder = (props) => {
  return (
    <div>
      <MapComponent coordinates={props.coordinates} zoom={props.zoom}></MapComponent>
    </div>
  );
};

export default Geocoder;
