import { useState } from "react";

const SearchBar = (props) => {
    const [query, setQuery] = useState('');

    return (
      <div>
        <div className="relative flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.Search(query); 
              }
            }}
            />
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <button
             className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400"
             onClick={() => {
                props.Search(query);
              }}
             >
              Search
            </button>
          </div>
        </div>
      </div>
    )
  }

export default SearchBar;