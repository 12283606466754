import {React, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignInScreen from './pages/SignInScreen'
import SignupScreen from './pages/SignupScreen.js';
import "./App.css";
import MemberDashboard from './pages/MemberDashboard.js';
import SigEpHomePage from './pages/SigEpHomePage.js';
import LoadingComponent from './components/Alerts/LoadingComponent.js';
import NoOrganization from './pages/NoOrganization.js';
import PageTrackingAnalytics from './services/PageTrackingAnalytics.js';
import ModeratorDashboard2 from './pages/ModeratorDashboard2.js';
import LandingPage from './pages/LandingPage.js';
function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [organization, setOrganization] = useState("");

  const SetAppUserPromise = (user) => {
    return new Promise(async (resolve, reject) => {
      try {
        setRole(user.Role);
        setOrganization(user.Organization);
        setCurrentUser(user);
        await storeUserInLocalStorage(user);
        setIsLoggedIn(true);
        setLoading(false);
        resolve(); 
      } catch (error) {
        console.error("Failed to set app user:", error);
        setLoading(false);
        reject(error); 
      }
    });
  };
  

  const removeAppUser = () => {
    setRole(null);
    setOrganization(null);
    setCurrentUser(null);
    setIsLoggedIn(false);
    setLoading(false);
    localStorage.removeItem('user');
  }
  
  const storeUserInLocalStorage = async (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  };

  if (loading) {
    return <div><LoadingComponent/></div>;
  }

  return (
    <Router>
      <PageTrackingAnalytics/>
      <Routes>
      <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <LandingPage setUser={SetAppUserPromise}  />} />
        <Route path="/signin" element={<SignInScreen setIsLoggedIn={setIsLoggedIn} setUser={SetAppUserPromise} />} />
        <Route path="/signup" element={<SignupScreen setIsLoggedIn={setIsLoggedIn} setUser={SetAppUserPromise} />} />
        <Route path="/dashboard" 
          element={
            isLoggedIn ? (
              organization ? (
                role === 'Admin' ? 
                  <ModeratorDashboard2 user={currentUser} removeAppUser={removeAppUser} /> : 
                  <MemberDashboard user={currentUser} removeAppUser={removeAppUser}/>
              ) : 
                <NoOrganization removeAppUser={removeAppUser} />
            ) : 
            <Navigate to="/" />
          } 
        />
      </Routes>
    </Router>
  );
}

export default App;