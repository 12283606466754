import { RadioGroup } from '@headlessui/react';
import React from 'react';

const OrganizationPlan = () => {
  const [selectedPlan, setSelectedPlan] = React.useState("Basic");

  return (
    <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
      <div>
        <h2 id="plan-heading" className="text-lg font-medium leading-6 text-gray-900">
          Subscription Plan
        </h2>
      </div>
      <RadioGroup value={selectedPlan} onChange={setSelectedPlan} className='pt-4' >
        <RadioGroup.Option
          value="Basic"
          className='relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pr-6 border-blue-200 bg-blue-50 rounded-md'
        >
          {({ active }) => (
            <>
              <span className="flex items-center text-sm">
                <span
                  className={
                    `bg-blue-500 border-transparent` + (active ? ' ring-2 ring-offset-2 ring-gray-900' : '') +
                    ` h-4 w-4 rounded-full border flex items-center justify-center`
                  }
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <RadioGroup.Label as="span" className="ml-3 font-medium text-gray-900">
                  Basic
                </RadioGroup.Label>
              </span>
              <RadioGroup.Description
                as="span"
                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
              >
                <span className='text-blue-900 font-medium'>
                  FREE
                </span>{' '}
              </RadioGroup.Description>
              <RadioGroup.Description
                as="span"
                className='text-blue-700 ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
              >
                First Semester
              </RadioGroup.Description>
            </>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option
          value="Basic"
          className='relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pr-6 border-blue-200 bg-white-50 rounded-md'
        >
          {({ active }) => (
            <>
              <span className="flex items-center text-sm">
                <span
                  className={
                    ` h-4 w-4 rounded-full border flex items-center justify-center`
                  }
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <RadioGroup.Label as="span" className="ml-3 font-medium text-gray-900">
                    Premium
                </RadioGroup.Label>
              </span>
              <RadioGroup.Description
                as="span"
                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
              >
                <span className='text-blue-900 font-medium'>
                  $99 / semester
                </span>{' '}
              </RadioGroup.Description>
              <RadioGroup.Description
                as="span"
                className='text-blue-700 ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
              >
                No limit
              </RadioGroup.Description>
            </>
          )}
        </RadioGroup.Option>
      </RadioGroup>
    </div>
  );
}

export default OrganizationPlan;
