import { useState, useCallback } from "react";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebase-config";
import "../styles/SignupScreen.css";
import AppUser from "../classes/AppUser";
import ErrorMessageAlert from "../components/Alerts/ErrorMessageAlert";
import { useNavigate } from 'react-router-dom';
import YearSelector from "../components/Misc/YearSelect";
import apiFetch from "../scripts/api";

const SignUpScreen = (props) => {
  const [signUpName, setSignUpName] = useState("");
  const [signUpCode, setsignUpCode] = useState("");
  const [signUpEmail, setsignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [signUpPledgeClass, setPledgeClass] = useState("");
  const [ErrorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const signUp = async () => {
    try {
      const codes = await apiFetch('/api/public/joincodes')
      const empty = emptyFields();

      let organization = '';
      for (const code of codes.data){
        if (code.JoinCode == signUpCode){
          organization = code.OrganizationName
        }
      }
      if(!empty){
        if(organization != null){
          const user = await createUserWithEmailAndPassword(auth, signUpEmail, signUpPassword).catch((error) => {
            if (error.code === 'auth/email-already-in-use') {
                setErrorMessage("Email Already In Use");
            } else if (error.code === 'auth/email-already-in-use'){
              setErrorMessage("Password must be at least 6 characters.");
            } else {
                alert(error.message);
            }
          });
          let app_user = new AppUser(signUpName, signUpEmail, user.user.uid, "Member", organization, signUpPledgeClass);
          await apiFetch('/api/public/userprofile', "POST", app_user)
          props.setUser(app_user);
          props.setIsLoggedIn(true);
          navigate('/dashboard');
        }else{
          setErrorMessage("Invalid Join Code");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const emptyFields = () => {
    const fields = [
        { name: 'First Last Name', value: signUpName },
        { name: 'Join Code', value: signUpCode },
        { name: 'Email', value: signUpEmail },
        { name: 'Password', value: signUpPassword },
        { name: 'Pledge Class', value: signUpPledgeClass }
    ];
    const emptyFields = fields.filter(field => field.value === '');
    if (emptyFields.length > 0) {
        const fieldNames = emptyFields.map(field => field.name);
        setErrorMessage(fieldNames.join(', ') + ' is/are empty');
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        return true;
    } else {
        return false; 
    }
}

  const googleLogin = async () => {
    //implement google login
  }

  const navigateTo = useCallback((route) => {
    navigate(`/${route}`);
  }, []);

  return (
    <div>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="./images/CCT1.png"
            alt="Chapter Check"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create an Account
        </h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
                <label htmlFor="full-name" className="block text-sm font-medium leading-6 text-gray-900">
                  First and Last Name
                </label>
                <div className="mt-2">
                  <input
                    id="full-name"
                    name="full-name"
                    type="text"  
                    autoComplete="name"
                    placeholder="JFohn Smith"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                      setSignUpName(event.target.value); 
                    }}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="code" className="block text-sm font-medium leading-6 text-gray-900">
                  Join Code
                </label>
                <div className="mt-2">
                  <input
                    id="code"
                    name="code"
                    type="text"  
                    autoComplete="Code"
                    required
                    placeholder="1234"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                      setsignUpCode(event.target.value); 
                    }}
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="johnsmith@gmail.com"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                      setsignUpEmail(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="******"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                      setSignUpPassword(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="pc" className="block text-sm font-medium leading-6 text-gray-900">
                  Pledge Class
                </label>
                <div className="mt-2">
                  <YearSelector Change={setPledgeClass}/>
                </div>
              </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-red-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={signUp}
              >
                Sign Up
              </button>
            </div>
          </div>
          <p className="mt-10 text-center text-sm text-gray-500">
            Already Have an Account?{' '}
            <a onClick={() => navigateTo("signin")} className="font-semibold leading-6 text-blue-600 hover:text-blue-400">
              Sign In
            </a>
          </p>
          <p className="mt-1 text-center text-sm text-gray-500">
            <button onClick={() => navigateTo("")} className="font-semibold leading-6 text-grey-600 hover:text-blue-100">
              Return Home
            </button>
          </p>
        </div>
        <div>
        {ErrorMessage &&
          <ErrorMessageAlert ErrorMessage={ErrorMessage}></ErrorMessageAlert>
        }
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
