import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MemberTabs = ({ onTabChange }) => {
  const [tabs, setSelectedTab] = useState([
    { name: 'All', href: '#', current: true },
    { name: 'Attendance', href: '#', current: false },
    { name: 'Excused Limit', href: '#', current: false },
    { name: 'Unexcused Limit', href: '#', current: false },
    { name: 'PCs', href: '#', current: false },
  ])

  const handleSelectChange = (selectedTab) => {
    setSelectedTab(tabs.map(tab => ({
      ...tab,
      current: tab.name === selectedTab
    })));
    onTabChange(selectedTab);
  };

   return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => handleSelectChange(e.target.value)}
          >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => handleSelectChange(tab.name)}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}
export default MemberTabs;