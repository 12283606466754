import { useEffect, useState } from "react";
import EventsReportsForm from "./EventsReportForm";
import MembersReportsForm from "./MembersReportsForm";
import LoadingComponent from "../../Alerts/LoadingComponent";
import apiFetch from "../../../scripts/api";

const Reports = (props) => {
    const [moderators, setModerators] = useState();
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {
        const fetchModerators = async () => {
            try {
                setIsLoading(true); 
                const moderatorsArray = await apiFetch('/api/auth/organizations/moderators');
                setModerators(moderatorsArray.data);
            } catch (error) {
                console.error('Failed to fetch moderators:', error);
            } finally {
                setIsLoading(false); 
            }
        };
        fetchModerators();
    }, [props.Organization]); 
    
    if (isLoading) {
        return <LoadingComponent/>;
    }
    return (
        <div>
            <MembersReportsForm OrganizationContext={props.OrganizationContext}/>
            <EventsReportsForm OrganizationContext={props.OrganizationContext} Moderators={moderators}/>
        </div>
    );
};

export default Reports;
