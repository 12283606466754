import MembersTable from "./MembersTable.js";

const MembersPage = (props) => {
    return (
        <div className="mx-auto my-8 p-4 sm:p-6 md:p-8 bg-white rounded-lg shadow-md ">
            {props.Members !== null && 
            <MembersTable Members={props.Members} OrganizationContext={props.OrganizationContext} Refresh={props.Refresh}></MembersTable>}
        </div>
    );
};

export default MembersPage;
