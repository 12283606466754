import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import YearSelector from '../../Misc/YearSelect.js';
import LoadingComponent from '../../Alerts/LoadingComponent.js';
import apiFetch from '../../../scripts/api.js';

const DeletePledgeClassModal = (props) => {
  const cancelButtonRef = useRef(null);
  const [pledgeClass, setPledgeClass] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await apiFetch("/api/auth/organization/pledge-class/members", "DELETE", {pledgeClass});
    setLoading(false);
    props.setOpen(!result.success); 
    props.Refresh();
  }

  if (loading) {
    return <LoadingComponent />;
  }

  const isDeleteEnabled = isConfirmed && pledgeClass;

  return (  
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => props.setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <form onSubmit={handleSubmit}>
                  <div className="mb-8">
                    <div className="flex items-center text-gray-900 mb-4">
                      <div className="text-lg font-semibold border-2 border-gray-900 rounded-full w-8 h-8 flex items-center justify-center mr-3">1</div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                        Select Pledge Class
                      </Dialog.Title>
                    </div>
                    <YearSelector Change={setPledgeClass} className="mt-2"/>
                  </div>
                  <div className="mb-8">
                    <div className="flex items-center text-gray-900 mb-4">
                      <div className="text-lg font-semibold border-2 border-gray-900 rounded-full w-8 h-8 flex items-center justify-center mr-3">2</div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                        Confirm Delete
                      </Dialog.Title>
                    </div>
                    <label className="flex items-center text-sm text-gray-600">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-red-600"
                        checked={isConfirmed}
                        onChange={(e) => setIsConfirmed(e.target.checked)}
                      />
                      <span className="ml-2">I confirm that my actions will delete the pledge class from my organization and this is a permanent action.</span>
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => props.setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-1.5 bg-red-600 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-gray-400"
                      disabled={!isDeleteEnabled}
                    >
                      Delete
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeletePledgeClassModal;
