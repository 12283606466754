import ConfirmAlert from "../../Alerts/ConfirmAlert.js";
import { useState } from "react";
import WarningMessage from "../../Alerts/WarningMessage.js";
import apiFetch from "../../../scripts/api.js";

const  ModeratorList = (props) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)
  const [openWarning, setOpenWarning] = useState(false)

  const handleDelete = (UID) => {
    if ((props.Moderators?.length || 0) > 1) {
      setSelected(UID);
      setOpen(true);
    }else{
      setOpenWarning(true);
    }
  }

  const confirmDelete = async () => {
    const endpoint = `/api/auth/organizations/userprofile/${selected}/update-role`;
    const body = { role: "Member" };
    const result = await apiFetch(endpoint, 'POST', body);
    setOpen(false);
  }

  return (
    <div className="px-4 sm:px-6 ">
      <ConfirmAlert open={open} setOpen={setOpen} Title={"Remove Moderator"} Message={"Are you sure you want to remove moderator access?"} confirm={confirmDelete}></ConfirmAlert>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Moderators</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users with admin permissions.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => props.SetAddOpen(true)}
            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Add Moderator
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {openWarning && <WarningMessage WarningMessage={"Must have one moderator."}/> }
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {props.Moderators.map((moderator) => (
                    <tr key={moderator.name}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {moderator.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Admin</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a onClick={()=> handleDelete(moderator.UID)} className="text-blue-600 hover:text-blue-900">
                          Remove Access<span className="sr-only">, {moderator.name}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModeratorList;