import ConfirmAlert from "../../Alerts/ConfirmAlert";
import { useState } from "react";
import apiFetch from "../../../scripts/api";

const  LocationCard = (props) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(false)

  const handleDelete = (name) => {
    setSelected(name);
    setOpen(true);
  }

  const confirmDelete = async () => {
    try {
      const storedUser = await localStorage.getItem('user');
      const org = JSON.parse(storedUser).Organization;
      const endpoint = `/api/auth/organizations/locations?locationName=${encodeURIComponent(selected)}`;
      const result = await apiFetch(endpoint, 'DELETE');
      console.log('Delete location result:', result.data);
      props.refresh();
      setOpen(false);
  } catch (error) {
      console.error('Error deleting location:', error);
      throw error;
  }
  }

  return (
    <div className="pb-10">
    <div className="md:flex md:items-center md:justify-between pb-5">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Locations for {props.Organization}
        </h2>
      </div>
    </div>
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
    <ConfirmAlert open={open} setOpen={setOpen} Title={"Remove Location"} Message={"Are you sure you want to remove this location for this organization?"} confirm={confirmDelete}></ConfirmAlert>
    <button 
      type="button"
      className="flex flex-col items-center justify-center space-y-2 relative w-full rounded-lg border-2 border-dashed border-gray-300 p-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 hidden md:flex"
      onClick={() => props.showAdd(true)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
      <span className="text-sm font-semibold text-gray-900">Add a new Location</span>
    </button>
    {props.Locations.map((location) => (
      <li key={location.Name} className="col-span-1 divide-y divide-gray-200 rounded-lg shadow aspect-w-10 aspect-h-7 relative overflow-hidden">
          <div className="absolute inset-0 bg-cover bg-center z-0" style={{backgroundImage: `url('./images/templatemap.jpg')`}}>
              <div className="absolute inset-0 bg-gray-500 opacity-80"></div> 
          </div>
          <div className="flex flex-col w-full items-center justify-between space-y-6 p-6 relative z-9"> 
                  <div className="flex-1 truncate">
                  <div className="flex flex-col items-center space-y-3"> 
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Radius: {location.Radius}
                      </span>
                      <h3 className="truncate text-sm font-medium text-gray-50">{location.Name}</h3>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-50">{location.Address}</p>
                  </div>
                  <svg onClick={()=> handleDelete(location.Name)} width="20" height="20" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M31.0346 12.1593L22.9022 20.2898L14.7717 12.1593L12.0615 14.8695L20.192 23L12.0615 31.1305L14.7717 33.8406L22.9022 25.7101L31.0346 33.8406L33.7448 31.1305L25.6143 23L33.7448 14.8695L31.0346 12.1593Z" fill="#FFFFFF"/>
                  </svg>
              </div>
            </li>
        ))}
    </ul>
    </div>
  )
}

export default LocationCard;