import DataBar from './dataBar.js';
import PastEvents from "./pastEvents.js";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from 'react';
import LoadingComponent from '../../Alerts/LoadingComponent.js';
import apiFetch from '../../../scripts/api.js';

const Dashboard = (props) => {
    const [stats, setStats] = useState(null);
    const [pastEvents, setPastEvents] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    const [update, setUpdate] = useState(false); 

    const Refresh = () => {
        props.Refresh()
        setUpdate(!update);
    }
  
    useEffect(() => {
        const fetchInformation = async () => {
            setIsLoading(true); 
            try {
                const localPastEvents = await apiFetch('/api/auth/organizations/past-events');
                setPastEvents(localPastEvents.data);
                const Statistics = await apiFetch("/api/auth/organizations/statistics");
                setStats(Statistics.data);
            } catch (error) {
                console.error("Failed to fetch locations or read", error);
            } finally {
                setIsLoading(false); 
            }
        };
  
        fetchInformation();
    }, [update]);
  
    if (isLoading) {
        return <LoadingComponent/>; 
    }
  
    return (
        <div>
            <div className="flex justify-end">
            <button
                type="button"
                className="ml-auto inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-grey-600"
                onClick={() => Refresh()}>
                <svg className="h-5 w-5 mr-3" viewBox="0 0 24 24">
                    <ArrowPathRoundedSquareIcon cx={1} cy={1} r={1} />
                </svg>
                Refresh Data
            </button>
            </div>
            {stats && <DataBar Stats={stats} />}
            {pastEvents && <PastEvents Events={pastEvents} user={props.user} OrganizationContext={props.OrganizationContext} />}
        </div>
    );
};

export default Dashboard;
