import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore} from "firebase/firestore";
import "firebase/analytics";
import { getAnalytics } from "firebase/analytics";

const ENVapiKey = process.env.REACT_APP_FIREBASE_KEY;

const firebaseConfig = {
    apiKey: ENVapiKey,
    authDomain: "chapter-check.firebaseapp.com",
    projectId: "chapter-check",
    storageBucket: "chapter-check.appspot.com",
    messagingSenderId: "464897689852",
    appId: "1:464897689852:web:becc5ad1888859f1f9ef79",
    measurementId: "G-9LTGWK8FEE"
  };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
