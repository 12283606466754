import { useState, useEffect } from "react";
import MemberTabs from "./MemberTabs.js";
import ActionsDropDown from "./ActionsDropdown";
import ResetDataModel from "./ResetDataModal.js";
import DeletePledgeClassModal from "./DeletePledgeClassModal.js";
import SearchBar from "./SearchBar.js";
import SemesterExemptionModal from './SemesterExemptionModal.js';

const MembersTable = (props) => {
  const memberlist = props.Members;
  const UnexcusedLimit = props.OrganizationContext.UnexcusedLimit;
  const [selectedTab, setSelectedTab] = useState('All');
  const [selectedMembers, setSelectedMembers] = useState(memberlist);
  const [openResetActions, setOpenResetActions] = useState(false);
  const [openDelete, setopenDelete] = useState(false);
  const [openSemesterExemptions, setOpenSemesterExemptions] = useState(false);

  useEffect(() => {
    setSelectedMembers(memberlist);
  }, [memberlist]); 

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    const sortedMembers = searchAndSortMembers(tabName, memberlist);
    setSelectedMembers(sortedMembers);
  };

  const handleSearch = (query) => {
    const sortedMembers = searchAndSortMembers(selectedTab, memberlist, query);
    setSelectedMembers(sortedMembers);
  }

  const handleDownload = async () => {
    //let orgId = await Get_Organization_Name_By_ID(props.OrganizationContext.Name);
    //const collectionPath = `Organizations/${orgId}/Members`;
    //await Export_CSV_Data(collectionPath);
  }

  const searchAndSortMembers = (sortOrder, members, searchQuery) => {
    let filteredMembers = searchQuery
        ? members.filter(member => member.DisplayName.toLowerCase().includes(searchQuery.toLowerCase()))
        : members;

    switch (sortOrder) {
      case 'Excused Limit':
          filteredMembers = filteredMembers
              .filter(member => member.ExcusedChapterAttendance >= UnexcusedLimit)
              .sort((a, b) => b.ExcusedChapterAttendance - a.ExcusedChapterAttendance);
          break;
      case 'Unexcused Limit':
          filteredMembers = filteredMembers
              .filter(member => member.UnexcusedChapterAttendance >= UnexcusedLimit)
              .sort((a, b) => b.UnexcusedChapterAttendance - a.UnexcusedChapterAttendance);
          break;
      case 'Attendance':
          filteredMembers.sort((a, b) => b.PresentChapterAttendance - a.PresentChapterAttendance);
          break;
      case 'PCs':
          filteredMembers.sort((a, b) => b.PledgeClass - a.PledgeClass);
          break;
      default:
          break;
    }
    return filteredMembers;
  }

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <ResetDataModel open={openResetActions} setOpen={setOpenResetActions} Refresh={props.Refresh}></ResetDataModel>
        <DeletePledgeClassModal open={openDelete} setOpen={setopenDelete} OrganizationContext={props.OrganizationContext} Refresh={props.Refresh}></DeletePledgeClassModal>
        <SemesterExemptionModal Open={openSemesterExemptions} setOpen={setOpenSemesterExemptions} Refresh={props.Refresh}></SemesterExemptionModal>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Chapter Attendance Report</h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage Organization Users
            </p>
          </div>
          <MemberTabs onTabChange={handleTabChange}></MemberTabs>
          <div className="pl-10 flex">
            <div className="hidden md:flex">
              <SearchBar Search={handleSearch}></SearchBar>
              <ActionsDropDown setOpenActions={setOpenResetActions} setOpenDelete={setopenDelete} handleDownload={handleDownload} setOpenSemesterExemptions={setOpenSemesterExemptions}></ActionsDropDown>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Pledge Class
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Role
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Chapter Attendance
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Excused Chapters
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Unexcused Chapters
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Study Hours
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {selectedMembers.map((person) => (
                    <tr key={person.UID}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {person.DisplayName}
                      </td> 
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.PledgeClass}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Role}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{person.PresentChapterAttendance}</td>
                      <td className={`whitespace-nowrap px-3 py-4 text-sm text-center ${person.ExcusedChapterAttendance >= UnexcusedLimit ? 'text-yellow-500' : 'text-gray-500'}`}>
                          {person.ExcusedChapterAttendance}
                      </td>
                      <td className={`whitespace-nowrap px-3 py-4 text-sm text-center ${person.UnexcusedChapterAttendance >= UnexcusedLimit ? 'text-red-500' : 'text-gray-500'}`}>
                          {person.UnexcusedChapterAttendance}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">{person.StudyHours}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

export default MembersTable;