import { useEffect, useState } from 'react';
import AddLocationForm from './AddLocationForm';
import LocationCard from './LocationCard';
import LoadingComponent from '../../Alerts/LoadingComponent';
import apiFetch from '../../../scripts/api';

const Locations = (props) => {
  const [locations, setLocations] = useState([]);
  const [orgName, setOrgName] = useState('');
  const [isLoading, setIsLoading] = useState(true); 
  const [update, setUpdate] = useState(false); 
  const [showAdd, setShowAdd] = useState(false);
  const Refresh = () => {
    setUpdate(!update);
  }

  useEffect(() => {
      const fetchLocations = async () => {
          setIsLoading(true); 
          try {
              const user = localStorage.getItem('user');
              const org = user ? JSON.parse(user).Organization : 'Default Org';
              const locationsData = await apiFetch('/api/auth/organizations/locations');
              setLocations(locationsData.data);
              setOrgName(org);
          } catch (error) {
              console.error("Failed to fetch locations or read from localStorage", error);
          } finally {
              setIsLoading(false); 
          }
      };

      fetchLocations();
  }, [update]);

  if (isLoading) {
      return <LoadingComponent/>; 
  }

  return (
    <div>
        <div className='border-b border-grey-900/10'>
            <LocationCard Locations={locations} Organization={orgName} refresh={Refresh} showAdd={setShowAdd}/>
        </div>
        {showAdd && <div className="hidden md:block mx-auto my-8 p-4 sm:p-6 md:p-8 bg-white rounded-lg shadow-md">
            <AddLocationForm refresh={Refresh}></AddLocationForm>
        </div>}
    </div>
  );
};

export default Locations;
