import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ArrowRightOnRectangleIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AddUserOrganization from '../components/Misc/AddUserOrganization';
import DeleteUserModal from '../components/Misc/DeleteUserModal';

const links = [
  {
    name: 'Enter Join Code',
    modal: 'ADD',
    description: 'Enter a join code for an existing organization.',
    icon: ArrowRightOnRectangleIcon,
  },
  { name: 'New Organization', modal: 'NEW', description: 'Submit a request for a new organization from the home page.', icon: PlusIcon },
  {
    name: 'Delete Account',
    modal: 'DELETE',
    description: 'Click here to delete your account.',
    icon: TrashIcon,
  },
]

const NoOrganization = (props) => {
  const [modal, setModal] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const navigate = useNavigate();

  const logout = async () => {
    await props.removeAppUser();
    navigate(`/`);
  }

  return (
    <div className="bg-white">
      <AddUserOrganization open={modal == 'ADD'} setOpen={setModal}/>
      <DeleteUserModal open={modal == 'DELETE'} setOpen={setModal}/>
      <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
        <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
          <p className="text-base font-semibold leading-8 text-blue-600">Error</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No Organization Found</h1>
          <p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
            Sorry, your account is not currently apart of an organization.
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
          <h2 className="sr-only">Options</h2>
          <ul role="list" className="-mt-6 divide-y divide-gray-900/5 border-b border-gray-900/5">
            {links.map((link, linkIdx) => (
              <li key={linkIdx} className="relative flex gap-x-6 py-6">
                <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                  <link.icon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                </div>
                <div className="flex-auto">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">
                    <a 
                        onClick={ () => setModal(link.modal)}
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      {link.name}
                    </a>
                  </h3>
                  <p className="mt-2 text-sm leading-6 text-gray-600">{link.description}</p>
                </div>
                <div className="flex-none self-center">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-10 flex justify-center">
            <a onClick={() => logout()}  className="text-sm font-semibold leading-6 text-blue-600">
              <span aria-hidden="true">&larr;</span>
              Logout To Home
            </a>
          </div>
        </div>
      </main>
      <footer className="border-t border-gray-100 py-6 sm:py-10">
        <div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-8 px-6 sm:flex-row lg:px-8">
          <p className="text-sm leading-7 text-gray-400">&copy; Chapter Check. All rights reserved.</p>
          <div className="hidden sm:block sm:h-7 sm:w-px sm:flex-none sm:bg-gray-200" />
        </div>
      </footer>
    </div>
  )
}
export default NoOrganization;