import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import AddEventForm from './AddEventForm.js';
import EditEventForm from './EditEventForm.js';
import LoadingComponent from '../../Alerts/LoadingComponent.js';
import '../../../styles/Modals.css';
import apiFetch from '../../../scripts/api.js';

const EventFormModal = (props) => {
  const cancelButtonRef = useRef(null);

  const [Moderators, setModeratorsArray] = useState('');
  const [Locations, setLocationsArray] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        setLoading(true);
        const moderators = await apiFetch('/api/auth/organizations/moderators');
        const locations = await apiFetch('/api/auth/organizations/locations');
        setLocationsArray(locations.data);
        setModeratorsArray(moderators.data);
      } catch (err) {
        console.error('Error fetching user profile:', err);
      } finally {
        setLoading(false);
      }
    };
    if (true) {
      fetchUserProfile();
    }
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:max-w-full p-8 ml-[5rem] md:max-w-3/4 lg:max-w-2">
              <div className="scrollbar-hidden overflow-auto max-h-[70vh]">
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <CalendarIcon className="h-6 w-6 text-grey-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-5 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Information
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                        </p>
                      </div>
                      {props.CRUD === "CREATE" &&
                        <AddEventForm modal={props.setOpen} Organization={props.Organization} Refresh={props.Refresh} Moderators={Moderators} Locations={Locations} />
                      }
                      {props.CRUD === "UPDATE" &&
                        <EditEventForm eventToEdit={props.event} Organization={props.Organization} modal={props.setOpen} Refresh={props.Refresh} Locations={Locations} />
                      }
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => props.setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default EventFormModal;
