import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import LoadingComponent from '../../Alerts/LoadingComponent.js';
import YearSelector from '../../Misc/YearSelect.js';
import WarningMessage from '../../Alerts/WarningMessage.js';
import apiFetch from '../../../scripts/api.js';

const ResetDataModel = (props) => {
  const cancelButtonRef = useRef(null);
  const [pledgeClassYear, setPC] = useState(false);
  const [attendanceCount, setAttendanceCount] = useState(false);
  const [unexcusedAbsenceCount, setUnexcusedAbsenceCount] = useState(false);
  const [excusedAbsenceCount, setExcusedAbsenceCount] = useState(false);
  const [unexcusedAbsenceOverLimit, setUnExcusedAbsenceOverLimit] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let countsToReset = {
      "present": attendanceCount,
      "unexcused": unexcusedAbsenceCount,
      "excused": excusedAbsenceCount,
      "unexcusedlimit": unexcusedAbsenceOverLimit
    };
  const endpoint = `/api/auth/organizations/pledgeclass/resetattendance`;
  const body = { pledgeClassYear, countsToReset };
  const result = await apiFetch(endpoint, 'POST', body);
  setLoading(false);
  props.setOpen(!result.data);
  props.Refresh();
  }

  if (loading) {
    return <div><LoadingComponent/></div>;
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => props.setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <WarningMessage WarningMessage={"Data will be automatically reset per semester."}></WarningMessage>
                <form onSubmit={handleSubmit} className='pt-5'>
                  <div>
                    <div className="mb-8">
                      <div className="flex items-center text-gray-900 mb-4">
                        <div className="text-lg font-semibold border-2 border-gray-900 rounded-full w-8 h-8 flex items-center justify-center mr-3">1</div>
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                          Select Pledge Class
                        </Dialog.Title>
                      </div>
                      <YearSelector Change={setPC} className="mt-2"/>
                    </div>
                    <div>
                      <div className="flex items-center text-gray-900 mb-4">
                        <div className="text-lg font-semibold border-2 border-gray-900 rounded-full w-8 h-8 flex items-center justify-center mr-3">2</div>
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6">
                          Select Statistics
                        </Dialog.Title>
                      </div>
                        <fieldset>
                          <legend className="text-sm font-medium text-gray-900">Select Statistics:</legend>
                          <div className="space-y-4 mt-2">
                            <Checkbox label="Members Attendance Count" checked={attendanceCount} onChange={setAttendanceCount} />
                            <Checkbox label="Members Unexcused Absence Count" checked={unexcusedAbsenceCount} onChange={setUnexcusedAbsenceCount} />
                            <Checkbox label="Members Excused Absence Count" checked={excusedAbsenceCount} onChange={setExcusedAbsenceCount} />
                            <Checkbox label="Members Exceeding Unexcused Absence Limit" checked={unexcusedAbsenceOverLimit} onChange={setUnExcusedAbsenceOverLimit} />
                          </div>
                        </fieldset>
                    </div>
                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Reset Data
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

function Checkbox({ label, checked, onChange }) {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
      />
      <label htmlFor={label} className="ml-3 text-sm text-gray-600">
        {label}
      </label>
    </div>
  );
}

export default ResetDataModel;
