const DataBar = (props) => {
    return (
      <div>
        <h3 className="text-2xl font-semibold leading-6 text-gray-900">Summary:</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {props.Stats.map((item) => (
            <div key={item.title} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">{item.title}</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {item.value != null ? (+item.value).toFixed(1) : 'None'}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    )
  }
  
export default DataBar;