import { addDoc, serverTimestamp, collection, getDoc, updateDoc, deleteDoc,  doc, getDocs, where, query} from 'firebase/firestore';
import {db, auth} from "../firebase-config";
import { deleteUser } from "firebase/auth";
import {OrgID, initializeGlobalVariables, Get_All_Join_Codes} from './FirestoreProxy';

// DATABASE Query to add User Profile to DB
// ASYNC - Parameters: App user OBJ to be added
export const Add_User_Profile = async (user) => {
    const organization = user.Organization;
    await initializeGlobalVariables(organization);
    const collectionPath = `Organizations/${OrgID}/Members`;
  
    try {
      const ref = collection(db, "UserProfiles");
      await addDoc(ref,{
        UID: user.UID,
        DisplayName: user.DisplayName,
        Email: user.Email,
        Role: user.Role,
        Organization: user.Organization,
        timestamp: serverTimestamp()
      })
      console.log('Profile Added.');
    } catch (error) {
      console.error('Error adding profile', error);
    }
    //Add Full profile to Organization
    try {
      const ref = collection(db, collectionPath);
      await addDoc(ref,{
        DisplayName: user.DisplayName,
        Email: user.Email,
        UID: user.UID,
        Role: user.Role,
        Organization: user.Organization,
        PledgeClass: user.PledgeClass,
        UnexcusedChapterAttendance: 0,
        PresentChapterAttendance: 0,
        ExcusedChapterAttendance: 0,
        timestamp: serverTimestamp()
      })
  
      console.log('Profile Added.');
    } catch (error) {
      console.error('Error adding profile', error);
    }
  };
  
// DATABASE Query to get User Profile from UID
// HELPER ASYNC - Parameters: UID
export const Get_User_Profile = async (uid) => {
    const userProfileReference = await collection(db, 'UserProfiles');
    const eventsSnapshot = await getDocs(userProfileReference);
    let userProfile = null;
    eventsSnapshot.forEach((doc) => {
            if(uid === doc.data().UID){
              userProfile = doc.data();
            }
    })
    return userProfile;
  };
  
// DATABASE Query to update user profiles in db and in organization
// ASYNC - Parameters: UID, orgName, role
export const Update_User_Profile_Role = async (uid, orgName, role) => {
    const userProfilesCollection = collection(db, 'UserProfiles');
  
    try {
      const q = query(userProfilesCollection, where('UID', '==', uid));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.size === 1) {
        const userProfileDoc = querySnapshot.docs[0].ref;
        const userData = (await getDoc(userProfileDoc)).data();
        userData.Role = role;
        await updateDoc(userProfileDoc, userData);
  
        console.log(`User Profile with UID ${uid} updated successfully with new role: ${role}`);
      } else if (querySnapshot.size === 0) {
        console.error(`User Profile with UID ${uid} not found`);
      } else {
        console.error(`Multiple User Profiles with the same UID (${uid}) found`);
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  
    try {
      const docPath = `Organizations/${OrgID}`;
      const orgDocRef = doc(db, docPath);
  
      const orgDoc = await getDoc(orgDocRef);
  
      if (orgDoc.exists()) {
        const orgData = orgDoc.data();
        let updatedModerators;
        if (orgData.Moderators.includes(uid)) {
          updatedModerators = orgData.Moderators.filter(moderatorUid => moderatorUid !== uid);
        } else {
          updatedModerators = [...orgData.Moderators, uid];
        }
  
        const updatedOrgData = {
          ...orgData,
          Moderators: updatedModerators,
        };
        await updateDoc(orgDocRef, updatedOrgData);
      }
      }catch (error) {
        console.error('Error updating user profile:', error);
      }
  
      try {
        const membersCollectionRef = collection(db, `Organizations/${OrgID}/Members`);
        const memberQuery = query(membersCollectionRef, where('UID', '==', uid));
        const memberQuerySnapshot = await getDocs(memberQuery);
  
        if (memberQuerySnapshot.size === 1) {
          const memberDocRef = memberQuerySnapshot.docs[0].ref;
          const memberData = (await getDoc(memberDocRef)).data();
          memberData.Role = role;
          await updateDoc(memberDocRef, memberData);
  
          console.log(`Member document for UID ${uid} updated successfully with new role: ${role}`);
        } else if (memberQuerySnapshot.size === 0) {
          console.error(`Member document with UID ${uid} not found`);
        } else {
          console.error(`Multiple Member documents with the same UID (${uid}) found`);
        }
      } catch (error) {
        console.error('Error updating member document:', error);
      }
  };

// DATABASE Query to Add user back to an organizaion
// ASYNC - Parameters: UID, join code, and pc to be added with
export const Update_User_To_Organization = async (uid, joinCode, pledgeClass) => {
    try {
      let organizationName = '';
      const codes = await Get_All_Join_Codes();
      for (const code of codes) {
        if (code.JoinCode === joinCode) {
          organizationName = code.OrganizationName;
          break;
        }
      }
      if (!organizationName) {
        console.error('Join code does not match any organization');
        return false;
      }
      const userProfilesRef = collection(db, "UserProfiles");
      const q = query(userProfilesRef, where("UID", "==", uid));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userProfileDoc = querySnapshot.docs[0];
         await updateDoc(userProfileDoc.ref, {
           Organization: organizationName,
           Role: "Member",
       });
      }
      const membersRef = collection(db, `Organizations/${OrgID}/Members`);
      const user = await Get_User_Profile(uid);
      await addDoc(membersRef, {
        UID: uid,
        DisplayName: user.DisplayName,
        Email: user.Email,
        Role: user.Role,
        Organization: organizationName,
        PledgeClass: pledgeClass,
        UnexcusedChapterAttendance: 0,
        PresentChapterAttendance: 0,
        ExcusedChapterAttendance: 0,
        timestamp: serverTimestamp()
      });
      console.log('User assigned to organization successfully.');
      return true;
    } catch (error) {
      console.error('Error assigning user to organization:', error);
      return false;
    }
  }
  
// DATABASE Query to Delete user upon request when no assigned organization
// ASYNC - Parameters:UID
export const Delete_User_No_Organization = async (UID) =>{
    const userProfilesCollection = collection(db, 'UserProfiles');
    let user = auth.currentUser;
  try {
      const q = query(userProfilesCollection, where('UID', '==', UID));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    } catch (error) {
      console.error('Error deleting the user:', error);
      return false;
    }
    deleteUser(user).then(() => {
      console.log('User account deleted successfully');
      localStorage.removeItem('user'); //Would rather do this in the front end.
      window.location.href = '/'; //Fix improper use in future. Current bug.
      return true;
    }).catch((error) => {
      console.error('Error deleting user account:', error);
      return false;
    });
  }