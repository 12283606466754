class AppUser {
    constructor(name, email, UID, role, organization, pledgeClass) {
      this.DisplayName = name;
      this.Email = email;
      this.UID = UID;
      this.Role = role;
      this.Organization = organization;
      this.PledgeClass = pledgeClass
    }
  
}

export default AppUser;