import GeocoderExample from './Geocoder';
import React, { useState } from 'react';
import ErrorMessageAlert from '../../Alerts/ErrorMessageAlert';
import SuccessMessageAlert from '../../Alerts/SuccessMessageAlert';
import apiFetch from '../../../scripts/api';

const AddLocationForm = (props) => {
  const [inputName, setInputName] = useState('');
  const [inputAddress, setInputAddress] = useState(''); 
  const [inputRadius, setInputRadius] = useState(''); 
  const [ErrorMessage, setErrorMessage] = useState('');
  const [SuccessMessage, setSuccessMessage] = useState('');
  const [mapZoom, setMapZoom] = useState(4);
  const [coordinates, setCoordinates] = useState([
    { lat: 40.82006746521739, lng: -96.69746086100744 },
    { lat: 40.818328334782606, lng: -96.69746086100744 },
    { lat: 40.818328334782606, lng: -96.69975893899255 },
    { lat: 40.82006746521739, lng: -96.69975893899255 }
  ]);

  const handleSearch = async (e) => {
    e.preventDefault(); 
    const address = encodeURIComponent(inputAddress); 
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY; 
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === 'OK') {
        setCoordinates(createSquare(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng, inputRadius));
        setMapZoom(16);
      } else {
        console.error('Geocode was not successful for the following reason: ' + data.status);
      }
    } catch (error) {
      console.error('Failed to fetch geocode:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const newLocation = {'Name': inputName, 'Address': inputAddress, 'Coordinates' : coordinates, 'Radius': inputRadius}
    const Name_Exists = await apiFetch(`/api/auth/check-name-existence?collectionName=Locations&name=${encodeURIComponent(inputName)}`);

    if(Name_Exists.success){
      setErrorMessage("Location name exists.");
      setTimeout(() => setErrorMessage(""), 3000);
      return; // Stop further execution if the name exists
    }
    try {
      const storedUser = await localStorage.getItem('user');
      const org = JSON.parse(storedUser).Organization;
      const result = await apiFetch('/api/auth/organizations/locations', 'POST', newLocation);
      if (result.success) {
        // Clear inputs
        setInputName(''); 
        setInputAddress(''); 
        setInputRadius('');
        setCoordinates([]); 
  
        setSuccessMessage("Location Successfully Added");
        setTimeout(() => {
          setSuccessMessage(""); 
          props.refresh(); 
        }, 3000);
      } else {
        setErrorMessage(result.error.message || "Check Input Fields");
        setTimeout(() => setErrorMessage(""), 3000);
      }
    } catch (error) {
      console.error('Error adding location:', error);
      setErrorMessage(error.message || "Error adding location");
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };
  
  const createSquare = (lat, lng, blockDistanceInMeters) => {
    const metersPerDegreeLat = 111000; // Roughly 111,000 meters in a degree of latitude
    const metersPerDegreeLng = Math.cos(lat * (Math.PI / 180)) * 111000; // Adjusted for latitude
    const latOffset = blockDistanceInMeters / metersPerDegreeLat;
    const lngOffset = blockDistanceInMeters / metersPerDegreeLng;
    const coordinates = [
      { lat: lat + latOffset, lng: lng - lngOffset }, // Top Left
      { lat: lat - latOffset, lng: lng - lngOffset }, // Bottom Left
      { lat: lat - latOffset, lng: lng + lngOffset }, // Bottom Right
      { lat: lat + latOffset, lng: lng + lngOffset }  // Top Right
    ];
    return coordinates;
  }

  return (

    <div className="flex flex-col md:flex-row pt-3">
      {ErrorMessage &&
      <ErrorMessageAlert ErrorMessage={ErrorMessage}></ErrorMessageAlert>
      }
      {SuccessMessage && 
        <SuccessMessageAlert SuccessMessage={SuccessMessage}/>
      }  
      
      <div className="md:flex-1 mt-10 md:mt-0">
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900 text-center">Add Location</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600 text-center">
                Enter name and address for your new location.
              </p>
              <div className="mt-10 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Location Name
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={(e) => setInputName(e.target.value)}
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Location Address
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      onChange={(e) => setInputAddress(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Radius (50-100 Recommended)
                  </label>
                  <div className="mt-2">
                    <input
                      onChange={(e) => setInputRadius(e.target.value)}
                      type="number"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>   
                <div className="sm:col-span-1 flex items-end gap-4">
                  <div>
                    <label htmlFor="search-button" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                      Update Map
                    </label>
                    <button
                        id="search-button"
                        onClick={handleSearch}
                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Search
                    </button>
                  </div>
                  <div>
                    <label htmlFor="add-location-button" className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                      Add Location
                    </label>
                    <button
                        id="add-location-button"
                        type="submit"
                        className="rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      >
                        Add Location
                    </button>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </form>
    </div>
    <div className="md:flex-1 pl-5">
          <GeocoderExample coordinates={coordinates} zoom={mapZoom}></GeocoderExample>
    </div>
  </div>
  )
}

export default AddLocationForm;