import { useState } from "react";
import WarningMessage from "../../Alerts/WarningMessage";
import apiFetch from "../../../scripts/api";
import { exportToCSV } from '../../../scripts/Utility';
import SemesterSelector from "../../Misc/SemesterSelect";

const EventsReportsForm = (props) => {
  const [eventType, setEventType] = useState('all');
  const [selectedModerator, setSelectedModerator] = useState('all');
  const [eventAttendance, setEventAttendance] = useState(0);
  const [exportOption, setExportOption] = useState('csv');
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [warningMessage, setWarningMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result = await generateEventsReport(); 
    if(!result){
      setWarningMessage("No events to report.")
    }
  }

  const generateEventsReport = async () => {
    try {
        const endpoint = `/api/auth/organizations/reports/events`;
        const body = { startDate, endDate, moderator: selectedModerator, eventAttendance, eventType};
        const result = await apiFetch(endpoint, "POST", body);
        exportToCSV(result.data);
        console.log('Events report generated:', result.data);
        return result.data;
    } catch (error) {
        console.error('Error generating events report:', error);
        throw error;
    }
};


  return (
    <div className="space-y-10 divide-y divide-gray-900/10">

      <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        
        <div className="px-4 sm:px-0">
          
          <h2 className="text-base font-semibold leading-7 text-gray-900">Generate Events Report</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            Reports based on event dates and data. 
          </p>
        </div>
        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl" onSubmit={handleSubmit}>

          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="event-type" className="block text-sm font-medium leading-6 text-gray-900">
                  Event Type
                </label>
                <div className="mt-2">
                  <select
                    id="event-type"
                    name="event-type"
                    onChange={(e) => setEventType(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <option value="all">All</option>
                    <option value="Chapters">Chapter</option>
                    <option value="Philanthropy">Philanthropy</option>
                    <option value="StudyHours">Study Hours</option>
                  </select>
                </div>
              </div>
                <div className="sm:col-span-6">
                    <label htmlFor="time-range" className="block text-sm font-medium leading-6 text-gray-900">
                    Time Range
                    </label>
                    <div className="mt-2 grid grid-cols-2 gap-4">
                    <input
                      type="date"
                      name="start-date"
                      id="start-date"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                      type="date"
                      name="end-date"
                      id="end-date"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                    </div>
                </div>
                <div className="sm:col-span-6">
                    <label htmlFor="member-status" className="block text-sm font-medium leading-6 text-gray-900">
                        Event Status
                    </label>
                    <div className="mt-2">
                        <select
                        id="member-status"
                        name="member-status"
                        onChange={(e) => setEventAttendance(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        >
                        <option value={0}>All</option>
                        <option value={props.OrganizationContext.Quorum}>Only Events Above Quorum</option>
                        </select>
                    </div>
                </div>
                <div className="sm:col-span-6">
                    <label htmlFor="member-status" className="block text-sm font-medium leading-6 text-gray-900">
                        Moderator
                    </label>
                    <div className="mt-2">
                      <select
                          id="select-moderator"
                          name="select-moderator"
                          autoComplete="selecte moderator"
                          onChange={(e) => setSelectedModerator(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                          <option value={"all"}>All</option>
                          {props.Moderators.map((moderator, index) => (
                            <option key={index} value={moderator}>{moderator}</option> 
                          ))}
                        </select>
                      </div>
                  </div>
                <div className="sm:col-span-6">
                    <label htmlFor="export-options" className="block text-sm font-medium leading-6 text-gray-900">
                      Export Options
                    </label>
                    <div className="mt-2 grid grid-cols-2 gap-4">
                    <select
                        id="export-options"
                        name="export-options"
                        onChange={(e) => setExportOption(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                        <option value="csv">Excel</option>
                        <option value="pdf" disabled>PDF</option>
                    </select>
                    <button
                        type="submit"
                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 w-full sm:w-auto"
                    >
                        Generate Report
                    </button>
                    </div>
                  </div>
                </div>
              </div>
              {warningMessage &&
                <WarningMessage WarningMessage={warningMessage}></WarningMessage>
              }
            </form>
        </div>
    </div>
  );
}

export default EventsReportsForm;