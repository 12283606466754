import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import apiFetch from '../../../scripts/api';
import YearSelector from '../../Misc/YearSelect';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';

const AddEventForm = (props) => {
  const [EventName, setEventName] = useState('');
  const [EventLocation, setEventLocation] = useState('');
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null);
  const [Moderator, setSelectedModerator] = useState('');
  const [chapterMeeting, setChapterMeeting] = useState(false);
  const [SendReport, setSendReport] = useState(false);
  const [IncludeExemptionForm, setIncludeExemptionForm] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [PollQuestion, setPollQuestion] = useState();
  const [EventType, setEventType] = useState();
  const [AssignedPC, setAssignedPC] = useState();


  const dateToCustomFormat = (date) => {
    if (!date) return { _seconds: 0, _nanoseconds: 0 }; 
  
    const milliseconds = date instanceof Date ? date.getTime() : new Date(date).getTime();
    return {
      seconds: Math.floor(milliseconds / 1000),
      nanoseconds: (milliseconds % 1000) * 1000000
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const temporaryEventData = {
      EventName,
      EventLocation,
      StartDate: dateToCustomFormat(StartDate),
      EndDate: dateToCustomFormat(EndDate),
      Moderator,
      chapterMeeting,
      SendReport,
      EventType,
      AssignedPC: (EventType === 'StudyHours') ? AssignedPC : undefined,
      IncludeExemptionForm: (EventType === 'Chapters') ? IncludeExemptionForm : undefined,
      PollQuestion: (EventType === 'Chapters' || EventType === 'StudyHours') ? PollQuestion : undefined
    }; 

    const hasInvalidFields = (data) => {
      const commonRequired = ['EventName', 'EventLocation', 'StartDate', 'EndDate', 'Moderator'];
      if (commonRequired.some(key => !data[key])) return true;
  
      switch (EventType) {
          case 'StudyHours':
              return !data.AssignedPC;
          case 'Chapter':
              return !data.IncludeExemptionForm || !data.PollQuestion;
          default:
              return false;
      }
    }

    const hasNullorEmpty = hasInvalidFields(temporaryEventData);
    const Name_Exists = await apiFetch(`/api/auth/check-name-existence?collectionName=${EventType}&name=${encodeURIComponent(EventName)}`);

    if(Name_Exists.success){
      setErrorMessage("Event name exists.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }else if(hasNullorEmpty){
      setErrorMessage("One or more field is empty.");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }else{
      try {
        await apiFetch(`/api/auth/organizations/events`, 'POST', temporaryEventData);
        console.log('Event added successfully:');
        props.modal(false); 
        props.Refresh();
      } catch (error) {
        console.error('Failed to add event:', error);
        setErrorMessage(error.message || 'Failed to add event');
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
    }
  }

  useEffect(() => {
    let message = '';
    if (EndDate && EndDate < new Date()) {
      message = 'Cannot save as the event end date has already passed.';
    } 
    else if (StartDate && EndDate && StartDate >= EndDate) {
      message = 'Start date must be before the end date.';
    }
    setValidationMessage(message);
  }, [StartDate, EndDate]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Event Info</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                Enter the event details. 
            </p>
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-3">
              <label htmlFor="event-name" className="block text-sm font-medium leading-6 text-gray-900">
                Event name 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="event-name"
                  id="event-name"
                  autoComplete="event-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  onChange={(e) => setEventName(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                Event Location
              </label>
              <div className="mt-2">
                <select
                  id="event-location"
                  name="event-location"
                  autoComplete="event-location"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  onChange={(e) => setEventLocation(e.target.value)}

                >
                 <option value="">--Select--</option>
                {props.Locations.map((location, index) => (
                    <option key={index} value={location.Name}>{location.Name}</option> 
                  ))}
                </select>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="event-date" className="block text-sm font-medium leading-6 text-gray-900">
                Event Start Time/Date
              </label>
              <div className="mt-2">
                <DatePicker
                  selected={StartDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText='mm/dd/yyyy hh:mm'
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
               />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="event-date" className="block text-sm font-medium leading-6 text-gray-900">
                Event End Time/Date
              </label>
              <div className="mt-2">
              <DatePicker
                selected={EndDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                placeholderText='mm/dd/yyyy hh:mm'
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              />
              </div>
            </div>
            
            <div className="sm:col-span-3">
              <label htmlFor="moderator" className="block text-sm font-medium leading-6 text-gray-900">
                Select Moderator
              </label>
              <div className="mt-2">
                <select
                  id="select-moderator"
                  name="select-moderator"
                  autoComplete="selecte moderator"
                  onChange={(e) => setSelectedModerator(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value="">--Select--</option>
                  {props.Moderators.map((moderator, index) => (
                    <option key={index} value={moderator}>{moderator}</option> 
                  ))}
                </select>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="event-type" className="block text-sm font-medium text-gray-900">
                Event Type
              </label>
              <select
                id="event-type"
                name="event-type"
                value={EventType}
                onChange={(e) => setEventType(e.target.value)}
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                <option value="">--Select Type--</option>
                <option value="Chapters">Chapter</option>
                <option value="Philanthropy">Philanthropy</option>
                <option value="StudyHours">Study Hours</option>
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Configurations</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
                Select Config Options
            </p>
          </div>
          <div className="max-w-2xl space-y-10 md:col-span-2">
            <fieldset>
              <div className="mt-6 space-y-6">
                {(EventType === 'Chapters' || EventType === 'StudyHours') && (
                  <div className="sm:col-span-6">
                    <label htmlFor="poll-question" className="block text-sm font-medium text-gray-900">
                      Poll Question
                    </label>
                    <input
                      type="text"
                      id="poll-question"
                      name="poll-question"
                      value={PollQuestion}
                      onChange={(e) => setPollQuestion(e.target.value)}
                      placeholder="Enter a polling question..."
                      className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                )}
                {EventType === 'Chapters' && (
                  <div className="sm:col-span-6">
                    <label htmlFor="exemption-form" className="flex items-center">
                      <input
                        type="checkbox"
                        id="exemption-form"
                        name="exemption-form"
                        checked={IncludeExemptionForm}
                        onChange={(e) => setIncludeExemptionForm(e.target.checked)}
                        className="mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                      Include Exemption Form
                    </label>
                  </div>
                )}
                {EventType === 'StudyHours' && (
                  <div className="sm:col-span-6">
                    <label htmlFor="pc-assignment" className="block text-sm font-medium text-gray-900">
                      Assign Pledge Class
                    </label>
                    <YearSelector Change={setAssignedPC} className="mt-2"/>
                  </div>
                )}
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
      {validationMessage && (
          <p className="text-sm text-red-500">{validationMessage}</p>
        )}
      {ErrorMessage && (
          <p className="text-sm text-red-500">{ErrorMessage}</p>
        )}
        <button
          type="submit"
          disabled={!!validationMessage} 
          className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
            validationMessage ? 'bg-gray-500 text-gray-100 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-500'
          } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
        >
          Save
        </button>
      </div>
    </form>
  )
}

export default AddEventForm;

