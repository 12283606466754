import React from "react";

const epochToTime = (epoch) => {
  const date = new Date(epoch._seconds * 1000); // Convert epoch to milliseconds
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time
};

const UsersListStudyHours = (props) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Clock In
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Clock Out
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Hours
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Poll Answer
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {props.Members.map((person) => (
                  <tr key={person.Email}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {person.Name} {person.DisplayName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person.ClockIn ? epochToTime(person.ClockIn) : '--'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {person.ClockOut ? epochToTime(person.ClockOut) : '--'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.Hours || "0" }</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.PollAnswer || 'None'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersListStudyHours;
