import React, { useEffect, useState } from 'react';

const MapComponent = (props) => {
    const [mapLoaded, setMapLoaded] = useState(false);
    let API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

    const initMap = () => {
        if (window.google) {
            try {
                let center = { lat: props.coordinates[0].lat, lng: props.coordinates[0].lng }
                let zoom = props.zoom;
                const map = new window.google.maps.Map(document.getElementById('map'), {
                    zoom: zoom,
                    center: center
                });
                const polygon = new window.google.maps.Polygon({
                    paths: props.coordinates,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                });
                polygon.setMap(map);
            } catch (error) {
                console.error('Error initializing the map:', error);
            }
        } else {
            setTimeout(initMap, 500); 
        }
    };

    useEffect(() => {
        if (mapLoaded) {
            initMap();
        }
    }, [mapLoaded, props.coordinates]);

    useEffect(() => {
        const isScriptLoaded = document.querySelector("script[src*='maps.googleapis.com']");
        if (!isScriptLoaded) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`;
            script.async = true;
            script.defer = true;
            window.initMap = initMap; 
            document.head.appendChild(script);
            script.onload = () => setMapLoaded(true);

            return () => {
                window.initMap = initMap;
                //document.head.removeChild(script);
            };
        } else {
            // If the script is already loaded, we directly set mapLoaded to true
            setMapLoaded(true);
        }
    }, []); 

    if (mapLoaded) {
        return <div id="map" style={{ height: '400px', width: '100%' }} />;
      }
};

export default MapComponent;
