import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ExcusedUsers from '../Dashboard/ExcusedUsers.js';
import LoadingComponent from '../../Alerts/LoadingComponent.js';
import '../../../styles/Modals.css';
import apiFetch from '../../../scripts/api.js';

const SemesterExemptionModal = (props) => {
  const cancelButtonRef = useRef(null);
  const [exemptions, setExemptions] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchExemptions = async () => {
      try {
        const semesterExemptions = await apiFetch(`/api/auth/organizations/exemption/semester`);
        setExemptions(semesterExemptions.data);
      } catch (error) {
        console.error('Error fetching exemptions:', error);
      } finally {
        setLoading(false); 
      }
    };
    fetchExemptions();
    return () => setLoading(true);  // Reset loading on unmount to prevent memory leaks
  }, []);

  if (loading) {
    return <LoadingComponent />; 
  }

  return (
    <Transition.Root show={props.Open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" scrollbar-hidden relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all ml-9 sm:my-8 sm:max-w-5xl sm:p-6" style={{ maxHeight: '70vh', overflow: 'auto' }}>
                <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                  Semester Exemption Records
                </Dialog.Title>
                {exemptions.length > 0 ?
                  <ExcusedUsers ExemptionRecords={exemptions} semester={true} />
                  :
                  <p className="mt-2 text-sm text-gray-500">No exemptions to display.</p>
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SemesterExemptionModal;
